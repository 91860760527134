<template>
  <SubNav
    class="subnav-position"
    :selected="'rentrolls'"
    :buildingId="buildingId"
  />
  <main>
    <form v-if="lease" @submit.prevent="handleSave" class="edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'RentRoll', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Edit Lease</h6>
      <div class="form-outline mb-4">
        <label>Tenant Company Name</label>
        <input
          type="text"
          required
          v-model="lease.tenant"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Suite</label>
        <input
          type="text"
          required
          v-model="lease.suite"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Usqft</label>
        <input
          type="number"
          step="1"
          required
          v-model="lease.usqft"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>$/Usqft</label>
        {{
          usqftPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
        }}
      </div>
      <div class="form-outline mb-4">
        <label>rsqft</label>
        {{
          rsqft.toLocaleString("en-US", {
            maximumFractionDigits: 1
          })
        }}
      </div>
      <div class="form-outline mb-4">
        <label>$/Rsqft</label>
        {{
          rsqftPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
        }}
      </div>
      <div class="form-outline mb-4">
        <label>Monthly Rent</label>
        <input
          type="number"
          step="0.01"
          required
          v-model="lease.rent"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Annual Rent</label>
        {{
          rentAnnual.toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
        }}
      </div>
      <div class="form-outline mb-4">
        <label>Other $</label>
        <input
          type="number"
          step="0.01"
          required
          v-model="lease.otherCosts"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Expiration Date</label>
        <input
          type="date"
          required
          v-model="lease.expirationDate"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Escalator (as %, e.g. 5.5 == 5.5%)</label>
        <input
          type="text"
          required
          v-model="lease.escalator"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Escalator Date</label>
        <input type="date" v-model="lease.escalatorDate" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Notice Date</label>
        <input type="date" v-model="lease.noticeDate" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Notes</label>
        <input type="text" v-model="lease.notes" class="form-control" />
      </div>
      <div class="error">{{ errorForm }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Save</button>
        </div>
        <div class="col">
          <router-link
            :to="{
              name: 'RentRoll',
              params: { buildingId: buildingId }
            }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
        <div class="col">
          <span class="btn btn-link text-danger" @click="handleDelete">
            Delete Lease
          </span>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import SubNav from "@/components/navigation/SubNav.vue";
import { timestamp } from "@/firebase/config";
import { ref, computed } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import { useRouter } from "vue-router";
export default {
  props: ["buildingId", "leaseId"],
  components: { SubNav },
  setup(props) {
    const isPending = ref(false);
    const errorForm = ref(null);
    const router = useRouter();

    const {
      error: errorUpdateLease,
      deleteDoc: deleteLease,
      updateDoc: updateLease
    } = useDocument(`rentrolls/${props.buildingId}/leaseList`, props.leaseId);

    const { error: errorGetLease, document: lease } = getDocument(
      `rentrolls/${props.buildingId}/leaseList`,
      props.leaseId
    );

    const rentAnnual = computed(() => {
      if (lease.value.rent) {
        return lease.value.rent * 12;
      } else {
        return 0;
      }
    });

    const usqftPrice = computed(() => {
      if (lease.value.rent && lease.value.usqft && lease.value.usqft > 0) {
        return (lease.value.rent * 12) / lease.value.usqft;
      } else {
        return 0;
      }
    });

    const rsqft = computed(() => {
      if (lease.value.usqft) {
        return lease.value.usqft * 1.11;
      } else {
        return 0;
      }
    });

    const rsqftPrice = computed(() => {
      if (lease.value.rent && usqftPrice && rsqft.value > 0) {
        return (lease.value.rent * 12) / rsqft.value;
      } else {
        return 0;
      }
    });

    const handleSave = async () => {
      try {
        isPending.value = true;
        errorForm.value = null;
        await updateLease({
          tenant: lease.value.tenant ? lease.value.tenant : "",
          suite: lease.value.suite ? lease.value.suite : "0",
          usqft: lease.value.usqft ? lease.value.usqft : 0,
          usqftPrice: usqftPrice.value ? usqftPrice.value : 0,
          rsqft: rsqft.value ? rsqft.value : 0,
          rsqftPrice: rsqftPrice.value ? rsqftPrice.value : 0,
          rent: lease.value.rent ? lease.value.rent : 0,
          rentAnnual: rentAnnual.value ? rentAnnual.value : 0,
          otherCosts: lease.value.otherCosts ? lease.value.otherCosts : 0,
          expirationDate: lease.value.expirationDate
            ? lease.value.expirationDate
            : "",
          escalator: lease.value.escalator ? lease.value.escalator : 0,
          escalatorDate: lease.value.escalatorDate
            ? lease.value.escalatorDate
            : "",
          noticeDate: lease.value.noticeDate ? lease.value.noticeDate : "",
          notes: lease.value.notes ? lease.value.notes : "",
          editedAt: timestamp()
        });
        isPending.value = false;
        if (errorUpdateLease.value) {
          errorForm.value = errorUpdateLease.value;
        } else {
          router.push({
            name: "RentRoll",
            params: { buildingId: props.buildingId }
          });
        }
      } catch (err) {
        isPending.value = false;
        errorForm.value = `System Error: ${err.message}`;
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      errorForm.value = null;

      await deleteLease();
      isPending.value = false;
      if (errorUpdateLease.value) {
        errorForm.value = errorUpdateLease.value;
      } else {
        router.push({
          name: "RentRoll",
          params: { buildingId: props.buildingId }
        });
      }
    };

    return {
      isPending,
      errorForm,
      errorGetLease,
      handleSave,
      handleDelete,
      lease,
      usqftPrice,
      rsqft,
      rsqftPrice,
      rentAnnual
    };
  }
};
</script>

<style>
</style>